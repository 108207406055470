class Header {
    constructor($header) {
        this.$header = $header
        this.$menuIconHolder = $header.find('.menu-icon-holder');
        this.$menuIcon = $header.find('.menu-icon');
        this.$nav = $header.find('nav');

        this.listeners();
    }

    listeners() {
        var that = this;

        this.$menuIconHolder.click(function(e){
            e.stopPropagation();
            that.$menuIcon.toggleClass('open');
            that.$nav.toggleClass('open');
        });

        this.$nav.click(function(e){
            e.stopPropagation();
        });

        $(window).click(function() {
            that.$menuIcon.removeClass('open');
            that.$nav.removeClass('open');
        })
    }
}

export default () => {
    $('header').each(function(){
        new Header($(this));
    })
}