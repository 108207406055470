class ImagePick {
    constructor($imagePick) {
        this.$imagePick = $imagePick;
        this.$open = $('.open-image-pick');
        this.$overlay = $('.overlay');
        this.$close = $imagePick.find('.close');
        this.$submit = $imagePick.find('input[type=submit]');
        this.listeners();
    }


    listeners() {
        var that = this;

        this.$open.on('click', function() {
            that.$imagePick.css('display', 'block');
            that.$overlay.css('display', 'block');
        });

        this.$close.on('click', function() {
            that.$imagePick.css('display', 'none');
            that.$overlay.css('display', 'none');
        });
    }
}

export default () => {
    $('.image-pick').each(function(){
        new ImagePick($(this));
    })
}
