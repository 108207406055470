class Media {
    constructor($media) {
        this.$media = $media
        this.$delete = $media.find('.delete');
        this.listeners();
    }

    listeners() {
        var that = this;

        this.$delete.on('click', function(e) {
            e.preventDefault();

            if (confirm("Weet je het zeker?")){
                var form = this.closest('form');
                form.submit();
            }
        })
    }
}

export default () => {
    $('.dashboardMedia').each(function(){
        new Media($(this));
    })
}
