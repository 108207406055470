class Confirm {
    constructor($button) {
        this.$button = $button
        this.listeners();
    }

    listeners() {
        var that = this;

        this.$button.on('click', function(e) {
            e.preventDefault();

            if (confirm("Weet je het zeker?")){
                document.location.href = that.$button.attr('href');
            }
        })
    }
}

export default () => {
    $('.confirmClick').each(function(){
        new Confirm($(this));
    })
}
