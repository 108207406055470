import tableDragger from 'table-dragger'

class MyDrag {
    constructor($table) {
        this.table = $table;
        this.form = $("form.order-save");
        this.submit = this.form.find("input[type=submit]");
        this.jsonInput = this.form.find('input.jsonOrder');
        this.orderInfo = $($table).find('.orderInfo');
        this.addPicture = this.form.find('.add-picture');
        this.addOpdrachtgever = this.form.find('.add-opdrachtgever');

        this.tBody = $($table).find('tbody');
        this.dragger;
        this.currentRow = 0;
        this.initDragger();
        this.listeners();
    }

    listeners() {
        let that = this;

        this.form.on('submit', function (e) {
            e.preventDefault();
            that.fillHiddenOrderField();
            this.submit();
        });

        this.addPicture.on('click', () => {
            this.addPictureInput();
        });

        this.addOpdrachtgever.on('click', () => {
            this.addOpdrachtgeverInput();
        });

        this.tBody.on('click', '.delete-row', function(e) {
            let row = this.closest("tr");
            row.remove();
            that.fillHiddenOrderField();
            that.currentRow--;
        })
    }

    initDragger() {
        this.dragger = tableDragger(this.table, {
            mode: 'row',
            animation: 100
        });

        this.dragger.on('drop',() => {
            this.submit.css("visibility", "visible");
            this.fillHiddenOrderField();
        });
    }

    addPictureInput() {
        this.tBody.prepend(
            "<tr class=\"orderInfo\" data-id='" + this.currentRow + "'>" +
            '   <td class="text-center align-middle">' +
            '       <svg class="drag-icon" id="Solid" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg">' +
            '           <path d="m400 216a23.928 23.928 0 0 1 -16.971-7.029l-127.029-127.03-127.029 127.03a24 24 0 0 1 -33.942-33.942l144-144a24 24 0 0 1 33.942 0l144 144a24 24 0 0 1 -16.971 40.971zm-127.029 264.971 144-144a24 24 0 0 0 -33.942-33.942l-127.029 127.03-127.029-127.03a24 24 0 0 0 -33.942 33.942l144 144a24 24 0 0 0 33.942 0z"/>' +
            '       </svg>' +
            '   </td>' +
            "   <td class=\"align-middle\">" +
            "       <input type='hidden' id='input-" + this.currentRow + "' value='' name='imageId-" + this.currentRow + "'>" +
            "       <img id='image-" + this.currentRow + "' src=''>" +
            "       <div data-num='" + this.currentRow + "' class='btn btn-outline-info open-image-pick'>Kies foto</div>" +
            "   </td>" +
            "   <td class=\"align-middle\"><input class='w-100' type='text' name='titel-" + this.currentRow + "' required></td>" +
            "   <td class=\"align-middle\"><input class='w-100' type='text' name='beschrijving-"+ this.currentRow +"'></td>" +
            '   <td class="align-middle"><div class="delete-row btn btn-danger">Delete</div></td>' +
            "</tr>"
        );

        this.currentRow++;
        this.dragger.destroy();
        this.initDragger();
    }

    addOpdrachtgeverInput() {
        this.tBody.prepend(
            '<tr class="orderInfo" data-id="' + this.currentRow + '">' +
                '<td class="align-middle">' +
                    '<svg class="drag-icon" id="Solid" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg">' +
                        '<path d="m400 216a23.928 23.928 0 0 1 -16.971-7.029l-127.029-127.03-127.029 127.03a24 24 0 0 1 -33.942-33.942l144-144a24 24 0 0 1 33.942 0l144 144a24 24 0 0 1 -16.971 40.971zm-127.029 264.971 144-144a24 24 0 0 0 -33.942-33.942l-127.029 127.03-127.029-127.03a24 24 0 0 0 -33.942 33.942l144 144a24 24 0 0 0 33.942 0z"/>' +
                    '</svg>' +
                '</td>' +
                '<td class="align-middle">' +
                    '<input class="w-100" type="text" name="naam-' + this.currentRow + '" value="" required> ' +
                '</td>' +
                '<td class="align-middle">' +
                    '<button class="delete-row btn btn-danger">Delete</button>' +
                '</td>' +
            '</tr>'
        );

        this.currentRow++;
        this.dragger.destroy();
        this.initDragger();
    }

    // setDataOrder() {
    //     this.orderInfo = $(this.table).find('.orderInfo');
    //
    //     this.orderInfo.each(function (){
    //         orderItems.push($(this).data('id'));
    //     });
    // }

    fillHiddenOrderField() {
        this.orderInfo = $(this.table).find('.orderInfo');
        let orderItems = [];

        this.orderInfo.each(function (){
            orderItems.push($(this).data('id'));
        });

        this.jsonInput.val(JSON.stringify(orderItems));
    }
}

export default () => {
    var table = document.getElementsByClassName('draggable-table')[0];

    if (table) {
        new MyDrag(table);
    }
}
