import Upload from './blocks/upload-block';
import SliderPage from './blocks/sliderPage';
import Header from './blocks/header';
import Media from './blocks/media';
import Slider from './blocks/slider';
import ImagePick from './blocks/image-pick';
import SingleImagePick from './blocks/single-image-pick';
import Confirm from './blocks/confirm';
import Drag from './blocks/drag';

require('./bootstrap');
require('slick-carousel');
require('jquery-ui-sortable');

$(function() {

    $(document).ready(function() {
        const csrfToken = $(".sortable-container").data('csrf');
        const updateUrl = $(".sortable-container").data('update-url');

        $(".sortable-container").sortable({
            items: "> div", // Select only direct child div elements
            cursor: "grabbing", // Set the cursor style during dragging
            containment: "parent", // Allow dragging only within the parent container
            tolerance: "pointer", // Detect the mouse position for better sorting
            update: function(event, ui) {
                // Get the new positions
                const newPositions = [];
                $(".sortable-container .image-holder").each(function () {
                    newPositions.push($(this).data('image-id'));
                });

                // Send the new positions to the server using AJAX
                $.ajax({
                    type: "POST",
                    url: updateUrl,
                    data: {
                        _token: csrfToken,
                        positions: newPositions
                    },
                    success: function (response) {
                        // Handle success if needed
                        console.log(response.message);
                    },
                    error: function (xhr, status, error) {
                        // Handle error if needed
                        console.error(error);
                    }
                });
            }
        });
    });

    Slider();
    SliderPage();
    Upload();
    Media();
    Header();
    ImagePick();
    SingleImagePick();
    Confirm();
    Drag();
});
