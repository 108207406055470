class Slider {
    constructor($slider) {
        this.$slider = $slider;
        this.$next = $('.next');
        this.$back = $('.back');

        this.init();
        this.listeners();
    }

    init() {
        this.$slider.slick({
            infinite: true,
            autoplay: true,
            autoPlaySpeed: 8000,
            dots: false,
            arrows: false,
            pauseOnHover: true,
            slidesPerRow: 1,
            slidesToShow: 1,
            slidesToScroll: 1,
            buttons: false,
            mobileFirst: true,
            pauseOnFocus: true,
            fade: false,
            speed: 1300,
        });
    }

    listeners() {
        var that = this;

        this.$next.on('click', function() {
            that.$slider.slick('slickNext');
        })

        this.$back.on('click', function() {
            that.$slider.slick('slickPrev');
        })

    }
}

export default () => {
    $('.slider').each(function(){
        new Slider($(this));
    })
}
