class ImagePick {
    constructor($imagePick) {
        this.$imagePick = $imagePick;
        this.$table = $('.draggable-table');
        this.$overlay = $('.overlay');
        this.$close = $imagePick.find('.close');
        this.$submit = $imagePick.find('button.save');
        this.number;
        this.mediaUrl = $('#media-url')[0].innerHTML;
        this.listeners();
    }


    listeners() {
        var that = this;

        this.$table.on('click', '.open-image-pick', function() {
            that.$imagePick.css('display', 'block');
            that.$overlay.css('display', 'block');
            that.number = $(this).data('num');
        });

        this.$close.on('click', function() {
            that.$imagePick.css('display', 'none');
            that.$overlay.css('display', 'none');
        });

        this.$submit.on('click' , function() {
            that.setHiddenField();
            that.$imagePick.css('display', 'none');
            that.$overlay.css('display', 'none');
        })
    }

    setHiddenField() {
        let imgName = $('input[name="pick"]:checked').val();
        let imgId = $('input[name="pick"]:checked').data('id');

        this.$table.find('#image-'+ this.number).attr('src', this.mediaUrl + "/img/xs/" +  imgName);
        this.$table.find('#input-'+ this.number).attr('value', imgId);
    }
}

export default () => {
    $('.single-image-pick').each(function(){
        new ImagePick($(this));
    })
}
