class SliderPage {
    constructor($page) {
        this.$page = $page;
        this.$header = $page.find('.new-container');
        this.$image = $page.find('.slider-img');
        this.$currentImage = $('.slick-current img.slider-img');
        this.$slider = $page.find('.slider');
        this.$sliderInfo = $page.find('.image-info-holder');

        this.maxWidth = this.$slider.width();

        this.init();
    }

    init() {
        this.$sliderInfo.each(function(){
            $(this).removeClass('hidden');
        })

        this.$image.each(function(){
            $(this).removeClass('hidden');
        })
    }
}

export default () => {
    $('.slider-page').each(function(){
        new SliderPage($(this));
    })
}
